<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 평가기간 -->
          <c-datepicker
            :range="true"
            label="평가기간"
            defaultStart="-3M"
            defaultEnd="3M"
            name="period"
            v-model="searchParam.period"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 평가자 -->
          <c-field
            label="평가자"
            name="userId"
            v-model="searchParam.userId"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 진행단계 -->
          <c-select
            :comboItems="statusItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="patrolCompleteFlag"
            label="LBLSTEPPROGRESS"
            v-model="searchParam.patrolCompleteFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="5S 환경/안전패트롤 목록"
      :columns="grid.columns"
      :data="grid.data"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <!-- 등록 -->
          <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="linkClick" />
          <!-- 검색 -->
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'patrol',
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            // 사업장
            label: 'LBLPLANT',
            align: 'center',
            sortable: true,
            style: 'width:80px',
          },
          {
            name: 'patrolName',
            field: 'patrolName',
            label: '제목',
            align: 'left',
            sortable: true,
            type: 'link',
            style: 'width:200px',
          },
          {
            name: 'patrolDate',
            field: 'patrolDate',
            // 평가일시
            label: '평가일시',
            align: 'center',
            sortable: true,
            style: 'width:120px',
          },
          {
            name: 'patrolUserNames',
            field: 'patrolUserNames',
            // 평가자
            label: '평가자',
            align: 'left',
            sortable: true,
            style: 'width:150px',
          },
          {
            name: 'patrolCompleteFlagName',
            field: 'patrolCompleteFlagName',
            // 진행단계
            label: 'LBLPROGRESS',
            align: 'center',
            sortable: true,
            style: 'width:80px',
          },
          {
            // 결재진행단계
            name: 'approvalStatusName',
            field: 'approvalStatusName',
            label: 'LBL00001609',
            align: 'center',
            sortable: true,
            style: 'width:80px',
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        vendorCd: null,
        userId: '',
        useFlag: 'Y',
        startYmd: '',
        endYmd: '',
        period: [],
      },
      statusItems: [
        { code: "N", codeName: "점검중" },
        { code: "Y", codeName: "점검완료" },
      ],
      deptUrl: '',
      searchUrl: '',
      editable: true,
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.deptUrl = selectConfig.mdm.dept.list.url;
      this.listUrl = selectConfig.sai.patrol.list.url;
      // code setting
      // list setting
      this.getList();
      this.setColumn();
    },
    getList() {
      if (this.searchParam.period && this.searchParam.period.length === 2) {
        this.searchParam.startYmd = this.searchParam.period[0];
        this.searchParam.endYmd = this.searchParam.period[1];
      } else {
        this.searchParam.startYmd = '';
        this.searchParam.endYmd = '';
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;

        this.$_.forEach(this.grid.data, _item => {
          if (_item.deptCdCnt) {
            let deptCdCntArr = _item.deptCdCnt.split(",");
            for (let element of deptCdCntArr) {
              let elementArr = element.split("|");
              _item[elementArr[1]] = elementArr[0]
            }
          }
        })
      },);
    },
    setColumn() {
      this.$http.url = this.deptUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        fivesFlag: 'Y',
      };
      this.$http.request((_result) => {
        let childs = []
        this.$_.forEach(_result.data, _item => {
          childs.push({
            name: _item.deptCd,
            field: _item.deptCd,
            label: _item.deptName,
            align: 'center',
            sortable: false,
            style: 'width:50px',
          })
        })
        this.grid.columns = this.$_.concat(this.grid.columns, {
          label: '평가결과 [활동 건 수]',
          align: 'center',
          child: childs
        },)
      },);
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      this.popupOptions.title = '5S 환경/안전패트롤 상세'; // 순회점검 상세
      this.popupOptions.param = {
        saiPatrolId: row ? row.saiPatrolId : ''
      };
      this.popupOptions.target = () => import(`${'./patrolDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
